import { useNavigate } from '@reach/router'
import React from 'react'
import type {
  CancelPanelOptions,
  CancelPanelProps,
} from '~/components/CancelFlowPanels/CancelFlowPanel.types'
import { TEST_ID } from '~/constants/cypress'
import GenericCancelPanel from '../../GenericCancelPanel/GenericCancelPanel.ui'

const PanelAreYouSure: React.FC<CancelPanelProps> = ({ panel }) => {
  const navigate = useNavigate()

  const modalOptions: Array<CancelPanelOptions> = [
    {
      cypressId: TEST_ID.CANCEL_FLOW_SAVE,
      targetSaveComponent: 'Edit Address',
      clickHandler: () => navigate('/edit-address'),
      text: 'Yes, send a replacement',
    },
  ]
  return <GenericCancelPanel modalOptions={modalOptions} panel={panel} />
}

export default PanelAreYouSure
